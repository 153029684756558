<!-- =========================================================================================
  File Name: ProductEdit.vue
  Description: Product Edit Page
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/product/pixinvent
========================================================================================== -->

<template>
  <div id="page-product-edit">

    <vx-card v-if="product_data">

      <div class="tabs-container px-6 pt-6" slot="no-body">

        <vs-tabs class="tab-action-btn-fill-conatiner" v-model="activeTab">
          <vs-tab icon="icon-settings" icon-pack="feather" label="General">
            <div class="tab-text">
              <product-edit-tab-general :data="product_data" class="mt-4" v-on:data_type_update="updateType" v-on:date_update="updateDate"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-dollar-sign" icon-pack="feather" label="Pricing" v-if="product_data.type === 'service'">
            <div class="tab-text">
              <product-edit-tab-pricing :data="product_data.pricing" class="mt-4"
                                        v-on:date_type_update="updatePricing"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-shopping-bag" icon-pack="feather" label="Children" v-if="product_data.type === 'bundle'">
            <div class="tab-text">
              <product-edit-tab-children :data="product_data" class="mt-4" v-on:date_update="updateDate"/>
            </div>
          </vs-tab>
          <vs-tab icon="icon-plus-square" icon-pack="feather" label="Finalize">
            <div class="tab-text">
              <product-edit-tab-finalize :data="product_data" class="mt-4"/>
            </div>
          </vs-tab>
        </vs-tabs>

      </div>

    </vx-card>
  </div>
</template>

<script>
import ProductEditTabGeneral from './ProductEditTabGeneral.vue';
import ProductEditTabChildren from './ProductEditTabChildren.vue';
import ProductEditTabPricing from './ProductEditTabPricing.vue';
import ProductEditTabFinalize from '@/views/apps/product/product-create/ProductEditTabFinalize';

// Store Module

export default {
  components: {
    ProductEditTabFinalize,
    ProductEditTabGeneral,
    ProductEditTabChildren,
    ProductEditTabPricing,
  },
  data() {
    return {
      product_data: {
        type: 'service',
        status: 'disabled',
        pricing: [],
        children: [],
        children_ids: []
      },
      product_not_found: false,
      activeTab: 0,
    };
  },
  watch: {
    activeTab() {

    },
  },
  methods: {
    updateDate(data) {
      this.product_data = data;
    },
    updateType(type) {
      this.product_data.type = type;
    },
    updatePricing(pricing) {
      this.product_data.pricing = pricing;
    },
  },
  created() {
    // this.$vs.loading();
  },
};

</script>
