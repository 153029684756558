<!-- =========================================================================================
  File Name: AddNewDataSidebar.vue
  Description: Add New Data - Sidebar component
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->


<template>
  <vs-sidebar class="add-new-data-sidebar items-no-padding" click-not-close color="primary" default-index="1"
              parent="body"
              position-right spacer v-model="isSidebarActiveLocal">
    <div class="mt-6 flex items-center justify-between px-6">
      <h4>{{ Object.entries(this.data).length === 0 ? 'ADD NEW' : 'UPDATE' }} PRICE</h4>
      <feather-icon @click.stop="isSidebarActiveLocal = false" class="cursor-pointer" icon="XIcon"></feather-icon>
    </div>
    <vs-divider class="mb-0"></vs-divider>

    <component :is="scrollbarTag" :key="$vs.rtl" :settings="settings" class="scroll-area--data-list-add-new">

      <div class="p-6">

        <!-- AMOUNT -->
        <vs-input @change="setAmountRounded" class="mt-5 w-full" icon="icon-dollar-sign" icon-pack="feather"
                  label="Amount"
                  name="item-amount" step=".01" type="number" v-model="dataAmount" v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('item-amount')">{{ errors.first('item-amount') }}</span>

        <div class="pt-5">
          <label class="text-sm">Pricing Interval</label>

          <v-select
            :options="intervals"
            color="danger"
            label="priceDisplay"
            v-model="dataInterval"/>

          <!-- INTERVAL COUNT -->
          <vs-input class="mt-5 w-full" label="Price Interval Count" name="item-interval-count" type="number"
                    v-if="dataInterval !== 'one-time'" v-model="dataIntervalCount" v-validate="'required'"/>
          <span class="text-danger text-sm" v-show="errors.has('item-interval-count')">{{
              errors.first('item-interval-count')
            }}</span>

        </div>
        <div class="pt-5">
          <label>Hidden</label>
          <vs-switch color="danger" v-model="dataHidden"/>

          <label>Archived</label>
          <vs-switch color="danger" v-model="dataArchived"/>
        </div>


      </div>
    </component>

    <div class="flex flex-wrap items-center p-6" slot="footer">
      <vs-button :disabled="!isFormValid" @click="submitData" class="mr-6">Submit</vs-button>
      <vs-button @click="isSidebarActiveLocal = false" color="danger" type="border">Cancel</vs-button>
    </div>
  </vs-sidebar>
</template>

<script>
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import vSelect from 'vue-select';

export default {
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {
      },
    },
  },
  components: {
    VuePerfectScrollbar,
    vSelect,
  },
  data() {
    return {

      dataId: null,
      dataAmount: 0,
      dataHidden: false,
      dataArchived: false,
      dataInterval: 'one-time',
      dataIntervalCount: 1,

      intervals: [
        'one-time',
        'year',
        'month',
        'week',
        'day',
      ],

      settings: { // perfectscrollbar settings
        maxScrollbarLength: 60,
        wheelSpeed: .60,
      },
    };
  },
  watch: {
    isSidebarActive(val) {
      if (!val) return;
      if (Object.entries(this.data).length === 0) {
        this.initValues();
        this.$validator.reset();
      } else {
        const {amount, interval, interval_count, archived, hidden} = JSON.parse(
          JSON.stringify(this.data),
        );

        // this.initValues();
        this.dataAmount = (amount||0).toFixed(2);
        this.dataArchived = archived;
        this.dataHidden = hidden;
        this.dataInterval = interval;
        this.dataIntervalCount = interval_count;
      }

      // Object.entries(this.data).length === 0 ? this.initValues() : { this.dataId, this.dataName, this.dataCategory, this.dataOrder_status, this.dataPrice } = JSON.parse(JSON.stringify(this.data))
    },
  },
  computed: {
    isSidebarActiveLocal: {
      get() {
        return this.isSidebarActive;
      },
      set(val) {
        if (!val) {
          this.$emit('closeSidebar');
          // this.$validator.reset()
          // this.initValues()
        }
      },
    },
    isFormValid() {
      return !this.errors.any() && this.dataAmount > 0 && this.dataInterval !== undefined;
    },
    scrollbarTag() {
      return this.$store.getters.scrollbarTag;
    },
  },
  methods: {
    setAmountRounded() {
      this.dataAmount = parseFloat(this.dataAmount).toFixed(2);
    },
    initValues() {
      this.dataAmount = 0;
      this.dataArchived = false;
      this.dataHidden = false;
      this.dataInterval = 'one-time';
      this.dataIntervalCount = 1;
    },
    submitData() {
      this.$validator.validateAll().then(result => {

        if (result) {
          const obj = {
            amount: parseFloat(this.dataAmount),
            recurring: true,
            interval: this.dataInterval,
            interval_count: parseInt(this.dataIntervalCount),
            archived: this.dataArchived,
            hidden: this.dataHidden,
          };

          if (obj.interval === 'one-time') {
            obj.interval = 'one-time';
            obj.recurring = false;
          }

          this.$emit('add_price', obj);
          this.$emit('closeSidebar');
        }

      });
    },
  },
};
</script>

<style lang="scss" scoped>
.add-new-data-sidebar {
  ::v-deep .vs-sidebar--background {
    z-index: 52010;
  }

  ::v-deep .vs-sidebar {
    z-index: 52010;
    width: 400px;
    max-width: 90vw;

    .img-upload {
      margin-top: 2rem;

      .con-img-upload {
        padding: 0;
      }

      .con-input-upload {
        width: 100%;
        margin: 0;
      }
    }
  }
}

.scroll-area--data-list-add-new {
  // height: calc(var(--vh, 1vh) * 100 - 4.3rem);
  height: calc(var(--vh, 1vh) * 100 - 16px - 45px - 82px);

  &:not(.ps) {
    overflow-y: auto;
  }
}
</style>
