<!-- =========================================================================================
  File Name: ProductEditTabInformation.vue
  Description: Product Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/product/pixinvent
========================================================================================== -->

<template>
  <div id="product-edit-tab-info">
    <product-edit-sidebar :data="sidebarData" :isSidebarActive="addNewDataSidebar" @closeSidebar="toggleDataSidebar"
                          v-on:add_price="addPrice"/>


    <div class="vx-row">
      <div class="vx-col w-full md:w-fyll">

        <!-- Col Header -->
        <vs-row vs-justify="space-between" vs-type="flex">
          <vs-col vs-align="flex-start" vs-justify="flex-start" vs-type="flex">
            <span class="leading-none font-medium" style="min-width:175px;">Add Price to Product</span>

            <vs-col vs-align="flex-end" vs-justify="flex-end" vs-type="flex">

              <vs-button @click="addNewData" class="ml-5" color="danger" type="gradient">
                Create an Entry
              </vs-button>
            </vs-col>
          </vs-col>


        </vs-row>

        <vs-row>


          <div class="flex items-end pt-10">
            <span class="leading-none font-medium">Existing Prices</span>
          </div>

          <vs-table :data="pricing" class="flex w-full pt-3">

            <template slot="thead">
              <vs-th>Price</vs-th>
              <vs-th>Hidden</vs-th>
              <vs-th>Archived</vs-th>
              <vs-th>Actions</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in pricing">

                <vs-td>{{ tr.display }}</vs-td>
                <vs-td>{{ tr.hidden ? 'Yes' : 'No' }}</vs-td>
                <vs-td>{{ tr.archived ? 'Yes' : 'No' }}</vs-td>
                <vs-td>
                  <vs-row vs-type="flex" vs-w="6">
                    <vs-button
                      @click="editData(tr)"
                      color="danger"
                      icon="icon-edit-2"
                      icon-pack="feather"
                      size="small"
                      type="gradient"
                    >Clone
                    </vs-button>

                    <vs-button
                      @click="removeData(tr)"
                      class="ml-2 d-inline-flex "
                      color="danger"
                      icon="icon-minus"
                      icon-pack="feather"
                      size="small"
                      type="gradient"
                    >Remove
                    </vs-button>
                  </vs-row>

                </vs-td>

              </vs-tr>
            </template>

          </vs-table>

        </vs-row>
      </div>

    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';

import ProductEditSidebar from './ProductEditSidebar';

export default {
  components: {
    vSelect,
    flatPickr,
    'product-edit-sidebar': ProductEditSidebar,
  },
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  mounted() {
    this.pricing = this.data;
  },
  data() {
    return {

      // Data Sidebar
      addNewDataSidebar: false,
      sidebarData: {},

      loading: false,
      model: null,
      pricing: [],
      price: null,

      children: [],

      data_local: JSON.parse(JSON.stringify(this.data)),
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },

  },
  methods: {
    addPrice(element) {
      element.display = `$${element.amount.toFixed(2)} One-Time`;

      if (element.recurring) {
        const ic = element.interval_count;
        const plural = ic !== 1;
        element.display = `$${element.amount.toFixed(2)} w/ access for ${ic} ${element.interval}${plural ? 's' : ''}`;
      }

      this.pricing.push(element);
      this.$emit('data_type_update', this.pricing);
    },
    addNewData() {
      this.sidebarData = {};
      this.toggleDataSidebar(true);
    },
    editData(data) {
      this.sidebarData = data;
      this.toggleDataSidebar(true);
    },
    removeData(data) {
      this.pricing = this.pricing.filter(a => {
        return a !== data;
      });

      this.$emit('data_type_update', this.pricing);
    },
    toggleDataSidebar(val = false) {
      this.addNewDataSidebar = val;
    },
    resetData() {
      this.data_local = Object.assign({}, this.data);
    },
  },
};
</script>
