<!-- =========================================================================================
  File Name: ProductEditTabInformation.vue
  Description: Product Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/product/pixinvent
========================================================================================== -->

<template>
  <div id="product-edit-tab-info">
    <div class="vx-row">
      <div class="vx-col w-full md:w-fyll">

        <!-- Col Header -->
        <div class="flex items-end">
          <feather-icon class="mr-2" icon="ProductIcon" svgClasses="w-5 h-5"/>
          <span class="leading-none font-medium">Add Children to Product</span>
        </div>

        <div class="vx-row">
          <!-- Col Content -->
          <div class="vx-col w-1/2 mt-6">

            <label class="text-sm" for="product">Product Search</label>
            <vue-simple-suggest
              :controls="{
                selectionUp: [38, 33],
                selectionDown: [40, 34],
                select: [13, 36],
                hideList: [27, 35]
              }"
              :debounce="200"
              :filter-by-query="false"
              :list="getList"
              :max-suggestions="10"
              :min-length="3"
              :nullable-select="true"
              :prevent-submit="true"
              @select="onSuggestSelect"
              display-attribute="text"
              mode="select"
              placeholder="Search Products..."
              ref="suggestComponent"
              v-model="model"
              value-attribute="id">

              <div class="g">
                <input id="product" type="text">
              </div>

              <template slot="misc-item-above" slot-scope="{ suggestions, query }">
                <div class="misc-item" v-if="!loading && suggestions.length === 0">
                  <span>Sorry, no matching options.</span>
                </div>
              </template>

              <div :title="scope.suggestion.description" slot="suggestion-item" slot-scope="scope">
                <div class="text">
                  <span v-html="boldenSuggestion(scope)"></span>
                </div>
              </div>

              <div class="misc-item" slot="misc-item-below" slot-scope="{ suggestions }" v-if="loading">
                <span>Loading...</span>
              </div>
            </vue-simple-suggest>


          </div>

          <div class="vx-col vx-row w-1/2 mt-6">
            <div class="vx-col sm:w-3/4 w-full">
              <label class="text-sm">Product Pricing</label>
              <v-select
                :disabled="pricing.length === 0"
                :options="pricing"
                color="danger"
                label="priceDisplay"
                v-model="price"/>
            </div>

            <div class="vx-col sm:w-1/4 w-full">
              <label class="text-sm">Add Child</label>
              <vs-button :disabled="price === null"
                         @click="addChild"
                         color="danger"
                         icon="icon-plus"
                         icon-pack="feather"
                         type="gradient"/>
            </div>


          </div>
        </div>

        <vs-row>


          <div class="flex items-end pt-10">
            <span class="leading-none font-medium">Existing Children</span>
          </div>

          <vs-table :data="children" class="flex w-full pt-3">

            <template slot="thead">
              <vs-th>Product</vs-th>
              <vs-th>Price</vs-th>
              <vs-th>Actions</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">

                <vs-td :data="tr.name">
                  <router-link :to="`/a/products/${tr.product_id}`">{{ tr.name }}</router-link>
                </vs-td>

                <vs-td>{{ tr.display }}</vs-td>
                <vs-td>
                  <vs-button @click="removeChild(tr.id)"
                             color="danger"
                             icon="icon-minus"
                             icon-pack="feather"
                             type="gradient"/>
                </vs-td>

              </vs-tr>
            </template>

          </vs-table>

        </vs-row>
      </div>

    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';

import 'vue-simple-suggest/dist/styles.css';

export default {
  components: {
    vSelect,
    flatPickr,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  mounted() {
    this.loadChildrenAttr();
  },
  data() {
    return {
      loading: false,
      model: null,
      pricing: [],
      price: null,

      children: [],

      data_local: JSON.parse(JSON.stringify(this.data)),
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },

  },
  methods: {
    loadChildrenAttr() {
      this.data_local.children_ids.forEach(child => {

        this.$http.get(`billing/products/unknown/prices/${child}`)
          .then(response => {
            this.children = [];

            if (response.status === 200) {

              let data = response.data.data;
              const productId = data.product_id;

              data.display = 'One-Time';

              if (data.recurring) {

                const ic = data.interval_count;
                const plural = ic !== 1;

                data.display = `$${data.amount.toFixed(2)} w/ access for ${ic} ${data.interval}${plural ? 's' : ''}`;

              }

              this.$http.get(`billing/products/${productId}`)
                .then(response => {
                  if (response.status === 200) {
                    this.children.push({...response.data.data, ...data});
                    this.initiateProductUpdate();
                  }
                });

            }

          });

      });
    },
    addChild() {
      this.data_local.children_ids.push(this.price.priceId);
      this.loadChildrenAttr();
      this.initiateProductUpdate();
    },
    removeChild(child) {
      this.data_local.children_ids = this.data.children_ids.filter(a => {
        return child !== a;
      });
      this.loadChildrenAttr();
      this.initiateProductUpdate();
    },
    getList(inputValue) {

      return new Promise((resolve, reject) => {
        this.loading = true;
        this.$http.post(`billing/products/search`, {name: inputValue, input: 10})
          .then(response => {

            let options = [];

            if (response.status === 200) {

              const data = response.data.data;

              let known = [];
              options = data
                .map(element => {
                  return {
                    id: element.id,
                    text: element.name,
                  };
                })
                .filter(a => {
                  if (known.includes(a.id)) return false;
                  known.push(a.id);
                  return true;
                });

            }

            this.loading = false;
            resolve(options);
          })
          .catch(exception => {
            this.loading = false;
            reject(exception);
          });
      });
    },
    onSuggestSelect(suggest) {
      this.selected = suggest;

      if (suggest instanceof Object) {

        this.$http.get(`billing/products/${suggest.id}/prices`)
          .then(response => {

            if (response.status === 200) {

              const data = response.data.data;

              this.pricing = data.map(element => {
                element.display = 'One-Time';

                if (element.recurring) {

                  const ic = element.interval_count;
                  const plural = ic !== 1;

                  element.display = `w/ access for ${ic} ${element.interval}${plural ? 's' : ''}`;

                }

                return {
                  priceDisplay: `$${element.amount.toFixed(2)} ${element.display}`,
                  priceId: element.id,
                };
              });

            }

          });

      }

    },
    boldenSuggestion(scope) {
      if (!scope) return scope;
      const {suggestion, query} = scope;

      let result = this.$refs.suggestComponent.displayProperty(suggestion);
      if (!query) return result;

      const texts = query.split(/[\s-_/\\|.]/gm).filter(t => !!t) || [''];
      return result.replace(new RegExp('(.*?)(' + texts.join('|') + ')(.*?)', 'gi'), '$1<b>$2</b>$3');
    },

    initiateProductUpdate() {
      this.data_local.children = this.children;
      this.$emit('date_update', this.data_local);
    },
    resetData() {
      this.data_local = Object.assign({}, this.data);
    },
  },
};
</script>


<style lang="scss">
@import "@/assets/scss/vuexy/extraComponents/autocomplete.scss";
</style>

