<!-- =========================================================================================
  File Name: ProductEditTabInformation.vue
  Description: Product Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/product/pixinvent
========================================================================================== -->

<template>
  <div id="product-edit-tab-info">
    <!-- Content Row -->

    <!-- Col Header -->
    <div class="flex items-end">
      <feather-icon class="mr-2" icon="ProductIcon" svgClasses="w-5 h-5"/>
      <span class="leading-none font-medium">Product Information</span>
    </div>

    <div class="vx-row pb-10">
      <div class="vx-col md:w-1/4 w-full">
        <vs-input @change="initiateProductUpdate" class="w-full mt-4" label="Product Name" name="product_name"
                  v-model="data_local.name"
                  v-validate="'required'"/>
        <span class="text-danger text-sm" v-show="errors.has('product_name')">{{ errors.first('product_name') }}</span>

      </div>

      <div class="vx-col md:w-1/4 w-full mt-4">

        <label class="vs-input--label" for="product_status">Service</label>
        <v-select :options="services" @input="initiateProductUpdate" class="w-full"
                  name="product_service" v-model="data_local.service"/>

      </div>
      <div class="vx-col md:w-1/4 w-full mt-4">

        <label class="vs-input--label" for="product_status">Product Status</label>
        <v-select :options="['disabled','active', 'hidden']" @input="initiateProductUpdate" class="w-full" label="test"
                  name="product_status" v-model="data_local.status"/>

      </div>
      <div class="vx-col md:w-1/4 w-full mt-4">

        <label class="vs-input--label" for="product_status">Product Type</label>
        <v-select :options="['service', 'bundle']" @input="typeChange" class="w-full" name="product_type"
                  v-model="data_local.type"/>

      </div>

      <div class="vx-col w-full mt-4">

        <vs-textarea @change="initiateProductUpdate" class="w-full" label="Product Description" height="200px"
                     name="product_description" v-model="data_local.description"/>

      </div>
    </div>
  </div>
</template>

<script>
import vSelect from 'vue-select';

export default {
  components: {
    vSelect,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
      services: []
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  methods: {
    typeChange() {
      this.$emit('data_type_update', this.data_local.type);
    },
    initiateProductUpdate() {
      this.$emit('date_update', this.data_local);
    },
    resetData() {
      this.data_local = JSON.parse(JSON.stringify(this.data));
    },
    loadServices() {
      this.services = [];

      this.$vs.loading();

      this.$http.get(`services?internal=true`)
        .then(response => {
          this.$vs.loading.close();

          if (response.status === 200) {

            this.services = response.data.data.map(service => {
              return {
                label: service.name,
                code: service.id
              }
            })
          }

        })
        .catch(exception => {
          this.$vs.loading.close();

          let details = 'An unknown error occurred while fetching the service list. This has been reported.';

          if (exception.response) {

            const response = exception.response;

            details = response.data.error.description;

          }

          return this.$vs.notify({
            title: 'Failed to fetch service list',
            text: details,
            color: 'danger',
            position: 'top-right',
          });
        });

    },
  },
  mounted() {
    this.loadServices();
  }
};
</script>
