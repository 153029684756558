<!-- =========================================================================================
  File Name: ProductEditTabInformation.vue
  Description: Product Edit Information Tab content
  ----------------------------------------------------------------------------------------
  Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
  Author: Pixinvent
  Author URL: http://www.themeforest.net/product/pixinvent
========================================================================================== -->

<template>
  <div id="product-edit-tab-info">

    <div class="vx-row pb-10">
      <div class="vx-col md:w-1/4 w-full">
        <vs-input class="w-full mt-4" disabled label="Product Name" name="product_name" v-model="data_local.name"
                  v-validate="'required'"/>

      </div>

      <div class="vx-col md:w-1/4 w-full mt-4">

        <label class="vs-input--label" for="product_status">Service</label>
        <v-select class="w-full" disabled name="service" v-model="data_local.service.label"/>

      </div>

      <div class="vx-col md:w-1/4 w-full mt-4">

        <label class="vs-input--label" for="product_status">Product Status</label>
        <v-select class="w-full" disabled label="test" name="product_status" v-model="data_local.status"/>

      </div>
      <div class="vx-col md:w-1/4 w-full mt-4">

        <label class="vs-input--label" for="product_status">Product Type</label>
        <v-select class="w-full" disabled name="product_type" v-model="data_local.type"/>

      </div>

      <div class="vx-col w-full mt-4">
        <vs-textarea class="w-full" disabled label="Product Description" name="product_description" height="200px"
                     v-model="data_local.description"/>
      </div>
    </div>

    <div class="vx-row">
      <div class="vx-col w-full md:w-fyll">

        <vs-row v-if="data_local.type === 'service'">

          <div class="flex items-end pt-10">
            <span class="leading-none font-medium">Pricing</span>
          </div>

          <vs-table :data="data_local.pricing" class="flex w-full pt-3">

            <template slot="thead">
              <vs-th>Price</vs-th>
              <vs-th>Hidden</vs-th>
              <vs-th>Archived</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data_local.pricing">

                <vs-td>{{ tr.display }}</vs-td>
                <vs-td>{{ tr.hidden ? 'Yes' : 'No' }}</vs-td>
                <vs-td>{{ tr.archived ? 'Yes' : 'No' }}</vs-td>

              </vs-tr>
            </template>

          </vs-table>

        </vs-row>

        <vs-row v-if="data_local.type === 'bundle'">

          <div class="flex items-end pt-10">
            <span class="leading-none font-medium">Existing Children</span>
          </div>

          <vs-table :data="data_local.children" class="flex w-full pt-3">

            <template slot="thead">
              <vs-th>Product</vs-th>
              <vs-th>Price</vs-th>
            </template>

            <template slot-scope="{data}">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data_local.children">

                <vs-td :data="tr.name">
                  <router-link :to="`/products/${tr.product_id}`" target="_blank">{{ tr.name }}</router-link>
                </vs-td>

                <vs-td>{{ tr.display }}</vs-td>

              </vs-tr>
            </template>

          </vs-table>

        </vs-row>
      </div>

    </div>

    <!-- Save & Reset Button -->
    <div class="vx-row">
      <div class="vx-col w-full">
        <div class="mt-8 flex flex-wrap items-center justify-end">
          <vs-button @click="createProduct" class="ml-auto mt-2">Create Product</vs-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import flatPickr from 'vue-flatpickr-component';
import 'flatpickr/dist/flatpickr.css';
import vSelect from 'vue-select';

import ProductEditSidebar from './ProductEditSidebar';

export default {
  components: {
    vSelect,
    flatPickr,
    'product-edit-sidebar': ProductEditSidebar,
  },
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      data_local: JSON.parse(JSON.stringify(this.data)),
    };
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },

  },
  methods: {
    resetData() {
      this.data_local = Object.assign({}, this.data);
    },
    createProduct() {

      const payload = {
        name: this.data_local.name,
        description: this.data_local.description,
        type: this.data_local.type,
        status: this.data_local.status,
        service: this.data_local.service.code
      };

      if (payload.name === undefined) {
        return this.$vs.notify({
          title: 'Failed to create product',
          text: 'You must supply a name for the product',
          color: 'danger',
          position: 'top-right',
        });
      }

      if (payload.type === 'bundle') {
        payload.children = this.data_local.children_ids;
        if (payload.children.length === 0) {

          return this.$vs.notify({
            title: 'Failed to create product',
            text: 'No children were provided',
            color: 'danger',
            position: 'top-right',
          });
        }
      } else if (payload.type === 'service') {
        payload.prices = this.data_local.pricing;

        if (payload.prices.length === 0) {

          return this.$vs.notify({
            title: 'Failed to create product',
            text: 'No prices were provided',
            color: 'danger',
            position: 'top-right',
          });
        }
      }

      this.$vs.loading();
      this.$http.post(`billing/products`, payload)
        .then(response => {
          this.$vs.loading.close();

          if (response.status === 200) {
            const data = response.data.data;

            this.$router.push(`/a/products/${data.id}`);
          }

        })
        .catch(exception => {
          this.$vs.loading.close();

          let error = 'An unknown error occurred while creating this product';
          if (exception.response) {
            error = exception.response.data.error.description;
          }

          return this.$vs.notify({
            title: 'Failed to create product',
            text: error,
            color: 'danger',
            position: 'top-right',
          });
        });


    },
  },
};
</script>
